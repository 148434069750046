<template>
  <div>
    <div :id="id" :style="{width: '30px', height: '30px'}"></div>
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入折线图图表，图表后缀都为 Chart
import { BarChart, PieChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

export default {
  name: "dashboard-img-echarts",
  props: ["columnarData", "id"],
  data() {
    return {
      option: {},
    };
  },
  created() {
    //配置配置项
    this.option = {
      grid: {
        left: '5%',
        top: '5%',
        right: '5%',
        bottom: '5%',
        containLabel: true 
      },
      series: [
        {
          type: 'pie',
          radius: '90%',
          data: this.columnarData,
          label: {
            show: false,
          },
        }
      ]
    };
    this.$nextTick(() => {
      // 注册必须的组件
      echarts.use([
        GridComponent,
        DatasetComponent,
        TransformComponent,
        BarChart,
        LabelLayout,
        UniversalTransition,
        CanvasRenderer,
        PieChart,
      ]);
      this.init();
    });
  },
  mounted() {},
  methods: {
    init() {
      var chartDom = document.getElementById(this.id);
      var myChart = echarts.init(chartDom);
      this.option && myChart.setOption(this.option);
    }
  }
};
</script>