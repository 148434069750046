<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle />

          <section class="dashboard-top">
            <el-row :gutter="20" class="fae">
              <el-col :span="20"> 
                <div class="clearfix fac lh15 pot mb6" @click="backQuestionList">
                  <span class="mr4 fac">
                    <img src="@/src/assets/common/back.png" width="16px" height="17px" />
                  </span>
                  <p class="f14">{{$t('questionnaire.quesList')}}</p>
                </div>
                <p class="f16 mb10">
                  <span class="color-teal">{{ detail.surveyName ? detail.surveyName : '--' }}</span>
                </p>
              </el-col>
              <el-col :span="4" class="fe mb10">
                <el-button size="large" type="primary" @click="exportList">{{$t('edpBackendCommon.export')}}</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="5" class="dashboard-item">
                <section class="fjs">
                  <div class="left">
                    <span class="icon">
                      <img src="@/src/assets/common/dashboard-views.png" width="24" height="24" />
                    </span>
                  </div>
                  <div class="right fcjsa">
                    <span class="label f16">{{$t('questionnaire.TotalViews')}}</span>
                    <span class="value f24">{{ detail.totalViews }}</span>
                  </div>
                </section>
              </el-col>
              <el-col :span="6" class="dashboard-item">
                <section class="fjs">
                  <div class="left">
                    <span class="icon bg-F4C24A">
                      <img src="@/src/assets/common/dashboard-completed-survey.png" width="24" height="24" />
                    </span>
                    </span>
                  </div>
                  <div class="right fcjsa">
                    <span class="label f16">{{$t('questionnaire.TotalCompletedSurvey')}}</span>
                    <span class="value f24">{{ detail.totalCompletedSurvey }}</span>
                  </div>
                </section>
              </el-col>
              <el-col :span="6" class="dashboard-item">
                <section class="fjs">
                  <div class="left">
                    <span class="icon bg-B8D1D0">
                      <img src="@/src/assets/common/dashboard-completed-today.png" width="24" height="24" />
                    </span>
                    </span>
                  </div>
                  <div class="right fcjsa">
                    <span class="label f16">{{$t('questionnaire.NewCompletedToday')}}</span>
                    <span class="value f24">{{ detail.totalCompletedToday }}</span>
                  </div>
                </section>
              </el-col>
              <el-col :span="7" class="dashboard-item">
                <section class="fjs">
                  <div class="left">
                    <span class="icon bg-B8CCE3">
                      <img src="@/src/assets/common/dashboard-average-time.png" width="24" height="24" />
                    </span>
                    </span>
                  </div>
                  <div class="right fcjsa">
                    <span class="label f16">{{$t('questionnaire.AverageTimetoAnswerSurvey')}}</span>
                    <span class="value f24">{{ `${Math.floor(detail.avgTimeToAnswerSurvey / 60).toString().padStart(2, '0')}:${(detail.avgTimeToAnswerSurvey % 60).toString().padStart(2, '0')}` }}</span>
                  </div>
                </section>
              </el-col>
            </el-row>
          </section>

          <template v-for="(question, index) in detail.statisticsQuestionList">
            <el-card class="edp-backend-conditionSearch" body-style="width: 100%;">
              <div slot="header" class="clearfix fac lh15 question-title">
                <strong class="title-line">Q{{ index + 1 }}: {{ question.name }} {{ `(${ questionTypeList.find(item => Number(item.id) === question.type) ? questionTypeList.find(item => Number(item.id) === question.type).label : question.type })` }}</strong>
              </div>
              <!-- echarts bar start -->
              <template v-if="question.type === 1 || question.type === 2 || question.type === 5">
                <el-row :gutter="20" class="fjc ">
                  <el-col :span="4">
                    <div class="fcc fac question-respondents w-130">
                      <span class="fjc bg-6FB999">{{ question.questionRespondents }}</span>
                      <span>{{$t('questionnaire.Response')}}</span>
                    </div>
                  </el-col>
                  <el-col :span="20">
                    <el-row></el-row>
                    <div class="fjsb">
                      <span>{{$t('questionnaire.Details')}}</span>
                      <span>{{$t('questionnaire.Response')}} (%)</span>
                    </div>
                    <div class="question-scroll">
                      <el-row>
                        <el-col :span="22">
                          <DashboardDefaultEcharts
                            :id="`${question.surveyQuestionId}`"
                            :columnarStyle="{width: '100%', height: computedEchartsHeight(question.reverseList)}"
                            :columnarXData="question.reverseList.map(item => item.content)"
                            :columnarYData="question.reverseList.map(item => item.optionRespondents)"
                            :questionRespondents="question.questionRespondents"
                          />
                        </el-col>
                        <el-col :span="2" v-if="question.surveyQuestionOptionList && question.surveyQuestionOptionList.length">
                          <ul class="echarts-right-text">
                            <li v-for="item in question.surveyQuestionOptionList" style="height: 38px;">
                              <span class="value">{{ item.optionRespondents }}</span>
                              <span class="percent">{{ computedRightText(question.questionRespondents, item.optionRespondents) }}</span>
                            </li>
                          </ul>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </template>
              <!-- echarts bar end -->
              <!-- table start -->
              <template v-if="question.type === 6 || question.type === 7 || question.type === 10 || question.type === 11">
                <section class="edp-backend-conditionSearch" style="margin: 0; padding: 0; box-shadow: 0 0 0 #fff;">
                  <div class="fjsb" style="width: 100%; margin-bottom: 20px;">
                    <div class="fjs question-respondents">
                      <span style="margin-left: 15px;">{{$t('questionnaire.Response')}}</span>
                      <span class="fjc bg-6FB999" style="margin-left: 15px; width: 52px; height: 52px; font-size: 22px;">{{ question.questionRespondents }}</span>
                    </div>
                    <div class="fjs">
                      <el-input
                        v-model="question.content"
                        :placeholder="`${$t('edpBackendCommon.search')} ${$t('questionnaire.ContentDetails')}`"
                        clearable
                        style="width: 368px;"
                        @change="fetchGapFilling(question)"
                      />
                      <el-button type="primary" @click="fetchGapFilling(question)" style="margin-left: 10px;">{{
                        $t("edpBackendCommon.search")
                      }}</el-button>
                    </div>
                  </div>
                </section>

                <section class="edp-backend-common-table" style="margin: 0; padding: 0; box-shadow: 0 0 0 #fff;">
                  <el-table :data="question.data" style="width: 100%">
                    <el-table-column prop="answerNumber" :label="`${$t('questionnaire.AnswerNumber')}`" width="120"/>
                    <el-table-column prop="answer" :label="question.name"/>
                    <el-table-column prop="cdsId" :label="`${$t('questionnaire.SubmittedBy')}`" width="120"/>
                  </el-table>
                  <div class="table-footer fjsb">
                    <span class="table-pagination-text">{{ question.total }} {{$t('edpBackendCommon.totalRecords')}}, {{ question.totalPage }}  {{$t('edpBackendCommon.pages')}}</span>
                    <el-pagination
                      :page-size="question.pageSize ? question.pageSize : 10"
                      :total="question.data && question.data.length ? question.total : 0"
                      :current-page="question.pageNo ? question.pageNo : 1"
                      background
                      hide-on-single-page
                      layout="prev, pager, next"
                      @current-change="(num) => gapFillingCurrentChange(num, question)"
                    />
                  </div>
                </section>
              </template>
              <!-- table end -->
              <!-- echarts rating start -->
              <template v-if="question.type === 8">
                <el-row :gutter="20" class="fjc ">
                  <el-col :span="5">
                    <div class="rating-left">
                      <div class="fcc fac question-respondents w-130">
                        <span class="fjc bg-6FB999">{{ question.questionRespondents }}</span>
                        <span>{{$t('questionnaire.Response')}}</span>
                      </div>
                      <i class="rating-line" />
                      <div class="fe">
                        <div class="question-average">
                          <span class="fjc bg-f4c24a">{{ computedAverage(question) }}</span>
                          <span>{{$t('questionnaire.AverageScore')}}</span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="19">
                    <div class="fjsb">
                      <span>{{$t('questionnaire.Details')}}</span>
                      <span>{{$t('questionnaire.Response')}} (%)</span>
                    </div>
                    <div class="question-scroll">
                      <el-row>
                        <el-col :span="22">
                          <DashboardRatingEcharts
                            :id="`${question.surveyQuestionId}`"
                            :columnarStyle="{width: '100%', height: `200px`}"
                            :columnarXData="question.reverseList.map(item => item.value)"
                            :columnarYData="question.reverseList.map(item => item.optionRespondents)"
                            :questionRespondents="question.questionRespondents"
                          />
                        </el-col>
                        <el-col :span="2">
                          <ul class="echarts-right-text">
                            <li v-for="item in question.surveyQuestionOptionList" style="height: 38px;">
                              <span class="value">{{ item.optionRespondents }}</span>
                              <span class="percent">{{ computedRightText(question.questionRespondents, item.optionRespondents) }}</span>
                            </li>
                          </ul>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </template>
              <!-- echarts rating end -->
              <!-- echarts img start -->
              <template v-if="question.type === 3 || question.type === 4">
                <el-row :gutter="20" class="fjc ">
                  <el-col :span="4">
                    <div class="fcc fac question-respondents w-130">
                      <span class="fjc bg-6FB999">{{ question.questionRespondents }}</span>
                      <span>{{$t('questionnaire.Response')}}</span>
                    </div>
                  </el-col>
                  <el-col :span="20">
                    <div class="fjsb">
                      <span>{{$t('questionnaire.Details')}}</span>
                    </div>
                    <div>
                      <ul class="fjs img-ul">
                        <li
                          v-for="img in question.surveyQuestionOptionList"
                          :key="img.surveyOptionId"
                        >
                          <div class="fc img-box">
                            <img :src="img.content" />
                          </div>
                          <div class="fac echarts-box">
                            <div>
                              <DashboardImgEcharts
                                :id="img.surveyOptionId"
                                :columnarData="computedImgData(img, question)"
                              />
                            </div>
                            <div class="fcc">
                              <span class="color-87bdb1">{{img.optionRespondents}}</span>
                              <span class="color-dab867">{{computedImgPercent(img, question)}}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </template>
              <!-- echarts img end -->
            </el-card>
          </template>
        </div>
      </section>
      <!-- content end -->
    </section>

  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import DashboardDefaultEcharts from "@/src/components/echarts/dashboard-bar-echarts";
import DashboardImgEcharts from "@/src/components/echarts/dashboard-img-echarts";
import DashboardRatingEcharts from "@/src/components/echarts/dashboard-rating-echarts";
import { cloneDeep } from "lodash";
export default {
  name: "dashboard",
  components: {
    DashboardDefaultEcharts,
    DashboardImgEcharts,
    DashboardRatingEcharts,
  },
  data() {
    return {
      searchParam: {
        employee: undefined,
        touchPoint: undefined,
      },
      pageSize: 10,
      total: 0,
      pageNo: 1,
      totalPage: 0,
      publicStatusList: [
        {
          label: this.$t('questionnaireTemplate.Published'),
          value: "1",
        },
        {
          label: this.$t('questionnaireTemplate.Unpublished'),
          value: "2",
        },
      ],
      templateData: [],
      dateils: {
        pageAddress: "string",
        publishSystems: "string",
        qrUrl: "string",
      },
      publish: {
        form: {
          anonymousFlag: 1,
          releaseChannels: [],
        },
        rules: {
          anonymousFlag: [
            { required: true, message: 'Please Choose Anonymous', trigger: 'change' }
          ],
          endTime: [
            { type: 'date', required: false, message: 'Please Choose Survey Closing Date', trigger: 'change' }
          ],
          releaseChannels: [
            { type: 'array', required: true, message: 'Please Choose Authentication', trigger: 'change' }
          ],
        }
      },
      shareData: {
        adfsGenerateUrl: undefined,
        adfsQrcode: undefined,
        appGenerateUrl: undefined,
        appQrcode: undefined,
        h5GenerateUrl: undefined,
        h5Qrcode: undefined,
        name: undefined,
        id: undefined,
        code: undefined,
      },
      shareDialog: {
        visible: false,
        touchPoints: [],
        defaultProps: {
          children: "children",
          label: "name",
        },
        dataUserTree: [],
        userPermitTreeResult: [],
        dataUserTreeShow: false,
        dataUserTreeDefault: [],
        dataUserTree_selectId: "",
      },
      shareRecordData: [],
      detail: {
        surveyCode: undefined,
        statisticsQuestionList: []
      },
      questionTypeList: [
        {
          id: "1",
          label: `${this.$t("questionnaire.SingleChoice")}`,
        },
        {
          id: "2",
          label: `${this.$t("questionnaire.MultipleChoice")}`,
        },
        {
          id: "3",
          label: `${this.$t("questionnaire.ImageSingle")}`,
        },
        {
          id: "4",
          label: `${this.$t("questionnaire.ImageMultiple")}`,
        },
        {
          id: "5",
          label: `${this.$t("questionnaire.Dropdown")}`,
        },
        {
          id: "6",
          label: `${this.$t("questionnaire.Cascading")}`,
        },
        {
          id: "7",
          label: `${this.$t("questionnaire.FreeText")}`,
        },
        {
          id: "8",
          label: `${this.$t("questionnaire.RatingScale")}`,
        },
        {
          id: "9",
          label: `${this.$t("questionnaire.lable")}`,
        },
        {
          id: "11",
          label: `${this.$t("questionnaire.employee")}`,
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(o, n) {},
    },
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.code = this.$route.query.code;
    if (this.id) {
      this.fetchDetail();
    }
    if (this.code) {
      this.detail.surveyCode = this.code;
    }
  },
  created() {},
  methods: {
    ...mapActions({
      templateList: "questionnaireTemplate/templateList",
      evaluationDetail: "questionnaireTemplate/evaluationDetail",
      gapFilling: "questionnaireTemplate/gapFilling",
      surveyView: "questionnaireTemplate/surveyView",
      publishQuestion: "questionnaireTemplate/publishQuestion",
      shareQrcode: "questionnaireTemplate/shareQrcode",
      shareRecordList: "questionnaireTemplate/shareRecordList",
      shareRecordExport: "questionnaireTemplate/shareRecordExport",
      shareUserTree: "questionnaireTemplate/shareUserTree",
      shareSurveyNotice: "questionnaireTemplate/shareSurveyNotice",
      surveyDelete: "questionnaireTemplate/surveyDelete",
      offlineQuestion: "questionnaireTemplate/offlineQuestion",
      onlineQuestion: "questionnaireTemplate/onlineQuestion",
      turnoffQuestion: "questionnaireTemplate/turnoffQuestion",
      turnonQuestion: "questionnaireTemplate/turnonQuestion",
      surveyCopy: "questionnaireTemplate/copySurvey",
      surveyQrcode: "questionnaireTemplate/surveyQrcode",
      surveyAnswerExport: "survey/surveyAnswerExport",
    }),
    backQuestionList() {
      this.$router.push({ path: 'backend-questionnaire' });
    },
    async exportList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { id } = this.$route.query;
      const res = await this.surveyAnswerExport({
        id,
      });
      console.log(res);
      const name = "问卷答案导出表.xlsx";
      const blob = new Blob([res]);
      const url = window.URL.createObjectURL(blob);
      const aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = url;
      aLink.setAttribute("download", name);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
      loading.close();
      this.$message({
        type: "success",
        message: this.$t("edpBackendCommon.actionSuccess"),
      });
    },
    computedAverage(item) {
      let total = 0;
      item.surveyQuestionOptionList.forEach(item => {
        switch (item.value) {
          case "1":
            total += item.optionRespondents * 1
            break;
          case "2":
            total += item.optionRespondents * 2
            break;
          case "3":
            total += item.optionRespondents * 3
            break;
          case "4":
            total += item.optionRespondents * 4
            break;
          case "5":
            total += item.optionRespondents * 5
            break;
          default:
            total += 0
            break;
        }
      });
      if (total === 0 && item.questionRespondents === 0) return 0;
      return Math.round(total / item.questionRespondents);
    },
    computedImgData(item, list) {
      const listData = list.surveyQuestionOptionList.map(item => item.optionRespondents);
      const total = listData.reduce((pre, cur) => pre + cur);
      return [
        { value: total, name: 'Total', itemStyle: { color: '#f0f2f5', } },
        { value: item.optionRespondents, name: 'Value', itemStyle: { color: '#62b496', } },
      ]
    },
    computedImgPercent(item, list) {
      const listData = list.surveyQuestionOptionList.map(item => item.optionRespondents);
      const total = listData.reduce((pre, cur) => pre + cur);
      const percent = `${item.optionRespondents / total * 100}`;
      return `(${Math.round(percent)}%)`;
    },
    computedEchartsHeight(list) {
      if (!list || !list.length) return '200px';
      return `${list.length * 40}px`;
    },
    computedRightText(questionRespondents, optionRespondents) {
      if (questionRespondents === 0 && optionRespondents === 0) return `(0%)`;
      const percent = `${optionRespondents / questionRespondents * 100}`;
      return `(${Math.round(percent)}%)`;
    },
    async fetchDetail() {
      await this.evaluationDetail({id: this.id}).then(response => {
        if (response.success) {
          this.detail = response.data;
          this.detail.statisticsQuestionList.map(item => {
            if (item.type === 6 || item.type === 7 || item.type === 10 || item.type === 11) {
              this.fetchGapFilling(item);
              console.log(item)
            } else if (item.type === 8) {
              const list = cloneDeep(item.surveyQuestionOptionList);
              item.reverseList = list.sort((a, b) => b.value - a.value);
            } else if (item.type === 1 || item.type === 2 || item.type === 5) {
              const list = cloneDeep(item.surveyQuestionOptionList);
              item.reverseList = list.reverse();
            }
          })
        } else {
          this.$message({
            type: "warning",
            message: response.msg,
          });
        }
      })
    },
    async clearGapFillingSearch(question) {
      this.$set(question, "content", "");
    },
    async fetchGapFilling(item) {
      this.$set(item, "pageNo", item.pageNo ? item.pageNo : 1);
      this.$set(item, "pageSize", item.pageSize ? item.pageSize : 10);
      const params = {
        current: item.pageNo,
        size: item.pageSize,
        surveyCode: this.detail.surveyCode,
        surveyQuestionId: item.surveyQuestionId,
        type: item.type,
        answerContent: item.content ? item.content : "",
      }
      await this.gapFilling(params).then(response => {
        if (response.success) {
          this.$set(item, "data", response.data);
          this.$set(item, "total", response.total);
          this.$set(item, "totalPage", response.totalPage);
        } else {
          this.$message({
            type: "warning",
            message: response.msg,
          });
        }
      })
    },
    gapFillingCurrentChange(num, question) {
      if (num === question.pageNo) return;
      this.$set(question, "pageNo", num);
      this.fetchGapFilling(question);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/src/styles/variable.scss";

.edp-backend-content-out {
  .edp-backend-conditionSearch {
    padding-top: 0;
  }
  .dashboard-top {
    margin: 20px 30px;
    border-radius: 5px;
    box-shadow: 0 0 0 transparent;
    .dashboard-item {
      section {
        padding: 20px 0 20px 20px;
        background-color: #fff;
      }
      .left {
        margin-right: 10px;
        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #6FB999;
          color: #fff;
        }
        .bg-F4C24A {
          background-color: #F4C24A;
        }
        .bg-B8D1D0 {
          background-color: #B8D1D0;
        }
        .bg-B8CCE3 {
          background-color: #B8CCE3;
        }
      }
      .right {
        .label {
          margin-bottom: 10px;
        }
        .value {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
  /deep/ .el-card.edp-backend-conditionSearch {
    .el-card__header {
      width: 100%;
    }
  }
  .title-line {
    display: flex;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -20px;
      display: block;
      width: 5px;
      height: 100%;
      transform: translate3d(0, -50%, 0);
      background-color: #3E886D;
    }
  }
}
.color-teal {
  color: #3E886D;
}
.bg-6FB999 {
  background-color: #6FB999;
  color: #fff;
}
.question-scroll {
  max-height: 200px;
  overflow-y: auto;
  .echarts-right-text {
    li {
      display: flex;
      align-items: center;
      .value {
        display: inline-flex;
        margin-right: 10px;
        font-weight: bold;
        font-size: 14px;
      }
      .percent {
        font-size: 14px;
      }
    }
  }
}
.question-respondents {
  &.w-130 {
    width: 130px;
  }
  .bg-6FB999 {
    width: 100px;
    height: 100px;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
  }
}
.question-average {
  width: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .bg-f4c24a {
    width: 70px;
    height: 70px;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #f4c24a;
    color: #fff;
  }
}
.rating-left {
  position: relative;
  max-width: 300px;
  .rating-line {
    position: relative;
    overflow: hidden;
  }
  .rating-line::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 130px;
    width: 2px;
    height: 100px;
    background-color: #cbcbcb;
    transform: rotate(-145deg);
    transform-origin: 0 0;
  }
}
.img-ul {
  li {
    width: 150px;
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    &:nth-child(1) {
      width: 82px;
      margin-right: 35px;
    }
    div.img-box {
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
      }
    }
    div.echarts-box {
      span.color-87bdb1 {
        color: #87bdb1;
        font-size: 16px;
      }
      span.color-dab867 {
        color: #dab867;
        font-size: 16px;
      }
    }
  }
}
.user-tree {
  height: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  &:first-child {
    border-right: 1px solid #eee;
    margin-right: 4%;

    .edp-backend-userPermitDialog-subTitle {
      padding-left: toPc(24);
    }
  }
}
.searchTitle {
  text-align: left;
  margin-top: 16px;
  img {
    position: absolute;
    margin-top: 2px;
  }
  span {
    margin-left: 20px;
  }
}
.flex-buttons {
  top: 14px;
}
.searchBtn {
  text-align: right;
}
.mt20 {
  margin-top: 16px;
}
.row {
  margin-bottom: 18px;
}
.ftClas {
  color: #3E886D;
  cursor: pointer;
}
.share-wrap {
  border-radius: 10px;
  >>> .el-dialog__header {
    background: rgb(242, 242, 242);
    .titles {
      background: rgb(242, 242, 242);
      width: 90%;
      font-size: 14px;
      color: rgb(0, 9, 91);
      text-align: right;
      i {
        font-size: 14px;
      }
    }
    .el-dialog__close:before {
      font-size: 22px;
      color: #333;
    }
  }
  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .desc {
    font-size: 14px;
    color: rgb(110, 110, 110);
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 140px;
    height: 140px;
    margin: 10px auto;
    padding: 4px;
    border: 1px solid rgb(153, 153, 153);
  }
  .links {
    text-align: center;
    font-size: 14px;
    color: rgb(153, 153, 153);
    span {
      font-size: 12px;
      color: rgb(23, 0, 244);
      text-decoration: underline;
    }
  }
  .copy-btn {
    display: block;
    margin: 5px auto;
  }
}
</style>
<style lang="less">
.questionnaires {
  button {
    cursor: pointer;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
}
</style>
