<template>
  <div>
    <div :id="id" :style="columnarStyle"></div>
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入折线图图表，图表后缀都为 Chart
import { BarChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  GridComponent,
  DatasetComponent,
  TransformComponent,
  VisualMapComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

export default {
  name: "dashboard-bar-echarts",
  props: ["columnarStyle", "columnarXData", "columnarYData", "id", "questionRespondents"],
  data() {
    return {
      option: {},
    };
  },
  created() {
    let sourceArray = [
      ['score', 'amount', 'product'],
    ];
    let objectDatas = [];
    const total = this.questionRespondents;
    const maxValue = Math.max(...this.columnarYData);
    for (var i = 0; i < this.columnarYData.length; i++) {
      const value = this.columnarYData[i];
      const score = (maxValue === 0 && value === 0) ? 0 : Math.round(value / maxValue * 100);
      const label = this.columnarXData[i];
      sourceArray.push([score, value, label]);
      objectDatas.push({
        value,
        itemStyle: {
          color: score < 30 ? '#F2C150' : (score >= 30 && score < 75) ? '#ABC9C7' : '#6CC6A5',
        },
      });
    }
    objectDatas.push({
      value: this.columnarYData.reduce((pre, cur) => pre + cur),
      itemStyle: {
        color: '#6CC6A5',
      },
    });
    //配置配置项
    this.option = {
      dataset: {
        // source: sourceArray,
      },
      grid: {
        left: '2%',
        top: 0,
        right: '2%',
        bottom: '5%',
        containLabel: true 
      },
      visualMap: {
        show: false,
        orient: 'horizontal',
        left: 'center',
        min: 10,
        max: 100,
        // Map the score column to color
        dimension: 0,
        inRange: {
          color: ['#F2C150', '#ABC9C7', '#6CC6A5']
        }
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          inside: true,
          color: '#fff',
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        },
      },
      yAxis: {
        type: "category",
        data: this.columnarXData,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#999',
          formatter: function (value) {
            return '{text|' + value + '}';
          },
          rich: {
            text: {
              color: "#000000",
              align: "left"
            }
          }
        },
      },
      series: [
        {
          // use dataset
          // encode: {
          //   // Map the "amount" column to X axis.
          //   x: 'amount',
          //   // Map the "product" column to Y axis
          //   y: 'product'
          // },
          // use computed object
          data: objectDatas,
          type: "bar",
          label: {
            show: false,
            position: 'right',
            valueAnimation: true,
            formatter: (params) => {
              const total = this.questionRespondents;
              if (total === 0 && params.data === 0) return `${params.data} (0%)`;
              const percent = `${params.data / total * 100}`;
              return `${params.data} (${Math.round(percent)}%)`;
            },
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
        }
      ]
    };
    this.$nextTick(() => {
      // 注册必须的组件
      echarts.use([
        GridComponent,
        DatasetComponent,
        TransformComponent,
        BarChart,
        LabelLayout,
        UniversalTransition,
        CanvasRenderer,
        VisualMapComponent,
      ]);
      this.init();
    });
  },
  mounted() {},
  methods: {
    init() {
      var chartDom = document.getElementById(this.id);
      var myChart = echarts.init(chartDom);
      this.option && myChart.setOption(this.option);
    }
  }
};
</script>